import Choices from 'choices.js';

function show() {
	const container = document.getElementById('contactForm');
	const url = 'https://feedback.technokratos.com/feedback/';
	const departmentControl = container.querySelector('[name="department"]');
	const fileControl = container.querySelector('[name="cv"]');
	const fileNameContainer = container.querySelector('[for="cv"] span.file span');
	const responseContainer = container.querySelector('.response');
	const button = container.querySelector('button');

	const choices = new Choices(departmentControl);

	Array.from(document.querySelectorAll('a[data-vacancy]'))
		.forEach(btn => {
			btn.addEventListener('click', function () {
				choices.setChoiceByValue(this.dataset.vacancy);
			})
		});

	fileControl.addEventListener('change', e => {
		const file = e.target.files[0];
		fileNameContainer.innerText = file ? file.name : '';
	});

	function postData(data) {
		return fetch(url, {
			method: 'POST',
			body: data
		})
			.then(response => {
				if (!response.ok) {
					throw response
				}
				return response.json();
			})
			.catch(e => {
				e.text().then(errorMessage => {
					console.error('Error', errorMessage);
					responseContainer.classList.add('error');
					addMessageToResponse('Error.', errorMessage)
				})

			})
	}

	function addMessageToResponse(title, text) {
		const titleElem = responseContainer.querySelector('.message-title');
		const textElem = responseContainer.querySelector('.message-text');
		titleElem.innerHTML = title;
		textElem.innerHTML = text;
	}

	function validateForm(formData) {
		function validateEmail(email) {
			const regex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
			return regex.test(email) ? '' : 'Введите корректный email';
		}

		function validateRequired(formData) {
			return Array.from(formData.keys()).filter(k => {
				if (k === 'cv') return fileControl.files.length === 0;
				return !formData.get(k)
			}).length === 0 ? '' : 'Заполните все поля';
		}

		return validateRequired(formData) ? validateRequired(formData) : validateEmail(formData.get('email'));
	}

	function submitForm(e) {
		e.preventDefault();
		responseContainer.classList.remove('success', 'error');
		const formData = new FormData(container);

		const validationErrors = validateForm(formData)

		console.log(validationErrors);

		if (validationErrors) {
			responseContainer.classList.add('show', 'error');
			addMessageToResponse('Error.', validationErrors);
			return;
		}

		responseContainer.classList.add('show', 'loading');
		if (fileControl.files.length === 0) {
			formData.delete('cv');
		}
		button.style.display = 'none';
		postData(formData).then(data => {
			if (data) {
				responseContainer.classList.add('success');
				addMessageToResponse('Success.', 'Данные отправлены!')
			}
		}).catch(e => {
			console.log(e)
		}).finally(() => {
			responseContainer.classList.remove('loading');
			button.style.display = 'block';
		});
	}

	container.addEventListener("submit", submitForm);
}

export default show;
