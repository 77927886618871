import * as THREE from 'three'
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import projectorGuy from '/assets/projectorGuy.png'
import projectorGuyNormals from '/assets/projectorGuyNormals.jpg'
import technokratosLogo from '/assets/technokratos.png'
import {CustomBloomPass} from "./fx/bloom-pass";
import {FilmPass} from "three/examples/jsm/postprocessing/FilmPass";
import polygon3 from '/assets/polygons/3.png'
import inView from "in-view";

window["THREE"] = THREE;

var width,
	height,
	composer,
	scene,
	renderer,
	camera,
	lightMaterial,
	triangles,
	trianglesTmp,
	playing;

const canvas = document.querySelector("#projectorGuy canvas");
const canvasContainer = document.querySelector("#projectorGuy");


inView("#projectorGuy")
	.on('enter', () => {
		playing = true;
		resize();
		animate();
	})
	.on('exit', () => {
		playing = false;
	});
function projectorGuyAnim() {
	init();

	addSprite();
	addTriangles();

	addPasses();
	resize();
	animate();
	window.addEventListener("resize", resize);
}

const loader = new THREE.TextureLoader();

function addTriangles() {
	triangles = [polygon3]
		.map(url => loader.load(url))

		.map(map => new THREE.SpriteMaterial({map}))
		.map(material => new THREE.Sprite(material));

	triangles[0].position.set(0, 0, -0.1);
	triangles[0].rotation.set(0, 0.2, 0);
	triangles[0].scale.set(1.5, 1.5, 1.5);

	triangles
		.forEach(sprite => {
			sprite.originalPosition = sprite.position.clone();
			scene.add(sprite);
		});

	trianglesTmp = triangles.map(() => new THREE.Vector3());
}

const LightShader = {
	uniforms: {
		normalsTexture: {type: "t", value: new THREE.TextureLoader().load(projectorGuyNormals)},
		diffuseTexture: {type: "t", value: new THREE.TextureLoader().load(projectorGuy)},
		lightTexture: {type: "t", value: new THREE.TextureLoader().load(technokratosLogo)},
		amount: {value: 0.04},
		transform: {
			value: [0, 0, 0, 0, 0, 0, 0, 0, 0]
		},
		translateX: {value: -0.23},
		translateY: {value: -0.01},
		time: {value: 0},
		scale: {value: 1.7},
	},

	vertexShader: `
		varying vec2 vUv;
		void main() {
			vUv = uv;
			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
		}
	`,

	fragmentShader: `
		uniform sampler2D lightTexture;
		uniform sampler2D normalsTexture;
		uniform sampler2D diffuseTexture;

		uniform float amount;

		uniform float translateX;
		uniform float translateY;
		uniform float scale;
		uniform float time;

		varying vec2 vUv;
		void main() {
			vec2 scaledPosition = vUv * vec2(scale, scale)  + vec2(translateX, translateY);
			float rotation = time * 0.0003;


			vec2 translateBeforeRotate = scaledPosition - vec2(0.6, 0.45);
			vec2 rotatedPosition = vec2(translateBeforeRotate.x * sin(rotation) + translateBeforeRotate.y * cos(rotation), translateBeforeRotate.y * sin(rotation) - translateBeforeRotate.x * cos(rotation));
			vec2 translateAfterRotate = rotatedPosition + vec2(0.6, 0.45);

			vec2 position = translateAfterRotate;

			vec4 normal = texture2D(normalsTexture, vUv);
			vec2 offset = amount * vec2(normal.r, normal.g);

			vec4 light = texture2D(lightTexture, position + offset);
			vec4 diffuse = texture2D(diffuseTexture, vUv);

			if (diffuse.a > 0.01) {
				gl_FragColor = diffuse + vec4(light.r, light.g, light.b, light.a) * 0.7 * normal.r * normal.g * diffuse.a;
			} else {
				gl_FragColor = vec4(1.0, 0.0, 0.0, 0.0);
			}
		}
	`
};

function getCamera() {
	const cam = new THREE.PerspectiveCamera(
		75,
		window.innerWidth / window.innerHeight,
		0.1,
		1000
	);

	cam.position.z = 10;
	cam.position.x = 0;
	cam.fov = 5;
	cam.updateProjectionMatrix();
	return cam;
}

function init() {
	scene = new THREE.Scene();
	camera = getCamera();

	renderer = new THREE.WebGLRenderer({canvas, alpha: true});
	renderer.setClearColor(0x000115, 0); // the default
}

function addPasses() {
	composer = new EffectComposer(renderer);
	const renderPass = new RenderPass(scene, camera);

	const bloomPass = new CustomBloomPass(
		new THREE.Vector2(window.innerWidth, window.innerHeight),
		1.5,
		0.4,
		0.85
	);
	bloomPass.threshold = 0.5;
	bloomPass.strength = 0.7;
	bloomPass.radius = 0;

	const filmPass = new FilmPass(0.5, 0.2, 1024, 0.2);

	composer.addPass(renderPass);

	composer.addPass(bloomPass);
	composer.addPass(filmPass);
}


function addSprite() {
	const size = 0.8;
	const geometry = new THREE.PlaneBufferGeometry(2 * size / 1.5, size);

	lightMaterial = new THREE.ShaderMaterial(LightShader);
	lightMaterial.transparent = true;
	// lightMaterial = new THREE.MeshBasicMaterial({color: 0xff0000, wireframe: true});

	const mesh = new THREE.Mesh(geometry, lightMaterial);
	scene.add(mesh);
}

const translateX = -0.23;
const translateY = -0.01;

function animate(time = 0) {
	if (!playing) {
		return;
	}
	requestAnimationFrame(animate);

	if (lightMaterial.uniforms) {
		lightMaterial.uniforms.time.value = time;

		lightMaterial.uniforms.translateX.value = translateX - 0.5 * targetMousePosition[0];
		lightMaterial.uniforms.translateY.value = translateY + 0.5 * targetMousePosition[1];
	}

	if (triangles) {
		triangles[0].material.rotation += 0.0015;
	}

	triangles.forEach((sprite, index) => {
		trianglesTmp[index]
			.set(targetMousePosition[0], -targetMousePosition[1], 0)
			.multiplyScalar((1 - index) * 0.02 + 0.03);

		sprite.position
			.copy(sprite.originalPosition)
			.add(trianglesTmp[index])
	});

	composer.render();
}

function resize() {
	if (!playing) {
		return;
	}
	width = canvasContainer.offsetWidth;
	height = canvasContainer.offsetHeight;
	camera.aspect = width / height;
	camera.fov = window.innerWidth < 1024 ? 5 : 6;
	camera.updateProjectionMatrix();
	renderer.setSize(width, height);
	composer.setSize(width, height);
}

export default projectorGuyAnim;
