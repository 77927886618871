import Siema from 'siema';
import inView from "in-view";

function printSlideIndex() {
	document.querySelector('.js-index').innerHTML = `${this.currentSlide + 1} `
}

function show() {
let playing = false;
	const mySiema = new Siema({
		duration: 200,
		easing: 'ease-out',
		perPage: 1,
		loop: true,
		onInit: printSlideIndex,
		onChange: printSlideIndex,
	});

	inView("#counts")
		.on('enter', () => {
			playing = true;
			animate();
		})
		.on('exit', () => {
			playing = false;
		});

	document.getElementById('prev').addEventListener('click', () => mySiema.prev());
	document.getElementById('next').addEventListener('click', () => mySiema.next());

	const counts = Array.from(document.querySelectorAll('#counts .count'))
		.map(count => ({
			span1: count.querySelector('span:nth-child(1)'),
			span2: count.querySelector('span:nth-child(2)'),
			span3: count.querySelector('span:nth-child(3)'),
		}));

	const triangles = [
		document.querySelector('#counts .counts-triangle:nth-child(1)'),
		document.querySelector('#counts .counts-triangle:nth-child(2)')
	];

	function animate(time = 0) {
		if (!playing) {
			return;
		}
		requestAnimationFrame(animate);

		counts.forEach(count => {
			count.span1.style.transform = `translate3d(${10 * targetMousePosition[0]}px, ${10 * targetMousePosition[1]}px, 0)`;
			count.span2.style.transform = `translate3d(${40 * targetMousePosition[0]}px, ${40 * targetMousePosition[1]}px, 0)`;
			count.span3.style.transform = `translate3d(${80 * targetMousePosition[0]}px, ${80 * targetMousePosition[1]}px, 0)`;
		});

		triangles[0].style.transform = `translate3d(${30 * targetMousePosition[0]}px, ${30 * targetMousePosition[1]}px, 0) rotate(${time * 0.005 % 360}deg)`;
		triangles[1].style.transform = `translate3d(${60 * targetMousePosition[0]}px, ${60 * targetMousePosition[1]}px, 0) rotate(${time * 0.01 % 360}deg)`;
	}

	animate();
}

export default show;
