import inView from "in-view";
import {scrollIntoView} from "./main";
import form from "./form";

function loadVacancies() {
	fetch('https://backend-vacancies.technokratos.com/api/v1/vacancy/')
		.then(res => res.json())
		.then(vacancies => {
			document.querySelector('[data-vacancy-list]').innerHTML = vacancies.map(v => `
				<div class="vacancy-item animate-up">
					<div class="title">${v.title}</div>
					<div class="exp">Опыт: ${v.experience}</div>
					<div class="desc">${v.short_description}</div>
					<a class="button gl" href="#contact" data-vacancy="${v.title}">
						<span class="icon arrow">Откликнуться</span>
					</a>
				</div>`.trim()).join('');

			document.querySelector('#type[name="department"]').innerHTML = [
				...vacancies.map(v => v.title),
				'Другое'
			].map(v => `
				<option value="${v}">${v}</option>
			`.trim()).join('');

			inView('[data-vacancy-list] .animate-up')
				.on('enter', el => {
					el.classList.add('show-animation');
				});
			document.querySelectorAll('[data-vacancy-list] a[href^="#"]').forEach(anchor => {
				anchor.addEventListener('click', function (e) {
					e.preventDefault();
					setTimeout(() => scrollIntoView(this.getAttribute('href')), 0);
				});
			});
			form();
		})
}

export default loadVacancies;
