import * as THREE from 'three'
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import {CustomBloomPass} from "./fx/bloom-pass";
import {FilmPass} from "three/examples/jsm/postprocessing/FilmPass";
import {CustomGlitchPass} from "./fx/glitch-pass";

const container = document.querySelector('#buttonGlitch');
const canvas = document.querySelector('#buttonGlitch canvas');
const buttons = document.querySelectorAll('.gl');
var width, height, composer, scene, renderer, camera, lightMaterial, glitchPass, spriteMesh;

buttons.forEach(button => {
	button.addEventListener('mouseenter', startGlitch);
	button.addEventListener('mouseleave', stopGlitch);
});

window.addEventListener('scroll', stopGlitch);


const GlitchShader = {
	uniforms: {
		uRect: {value: [0, 0, 0, 0]},

	},
	vertexShader: `
		varying vec2 vUv;
		void main() {
			vUv = uv;
			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
		}
	`,

	fragmentShader: `
		uniform vec4 uRect;
		varying vec2 vUv;

		bool inRect(vec2 c) {
			return c.x >= uRect.x && c.x <= uRect.y && c.y <= uRect.z && c.y >= uRect.w;
		}

		void main() {
			if (inRect(vUv)) {
				gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0);
			} else {
				gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
			}
		}
	`
};

function buttonGlitch() {
	init();
	addPasses();
	addSprite();
	resize();
	animate();
	window.addEventListener("resize", resize);
}

function init() {
	scene = new THREE.Scene();
	camera = new THREE.PerspectiveCamera(
		75,
		window.innerWidth / window.innerHeight,
		0.1,
		1000
	);
	renderer = new THREE.WebGLRenderer({canvas, alpha: true});
	renderer.setClearColor(0x000115, 0); // the default
	camera.position.z = 10;
	camera.fov = 5;
	camera.updateProjectionMatrix();
}

function addSprite() {
	const geometry = new THREE.PlaneBufferGeometry(1, 1);
	const material = new THREE.ShaderMaterial(GlitchShader);
	// const material = new THREE.MeshBasicMaterial({color: 0xff0000, wireframe: true});
	spriteMesh = new THREE.Mesh(geometry, material);
	scene.add(spriteMesh);
}

function animate(time = 0) {
	requestAnimationFrame(animate);
	composer.render();
}

function addPasses() {
	composer = new EffectComposer(renderer);
	const renderPass = new RenderPass(scene, camera);

	const bloomPass = new CustomBloomPass(
		new THREE.Vector2(window.innerWidth, window.innerHeight),
		1.5,
		0.4,
		0.85
	);
	bloomPass.threshold = 0.5;
	bloomPass.strength = 0.7;
	bloomPass.radius = 0;

	glitchPass = new CustomGlitchPass();
	glitchPass.small = true;
	glitchPass.goWild = true;

	const filmPass = new FilmPass(0.5, 0.2, 1024, 0.2);

	composer.addPass(renderPass);
	composer.addPass(glitchPass);

	composer.addPass(bloomPass);
	composer.addPass(filmPass);
}

function startGlitch(e) {
	const el = e.target;
	const rect = el.getBoundingClientRect();
	el.parentNode.appendChild(container);
	container.style.top = '0px';
	container.style.left = '0px';
	let { x, y } = container.getBoundingClientRect();
	container.style.top = `${y * -1}px`;
	container.style.left = `${x * -1}px`;
	el.classList.add('active');
	const relativeRect = [rect.left / width, rect.right / width, 1 - rect.top / height, 1 - rect.bottom / height];
	GlitchShader.uniforms.uRect.value = relativeRect;
	glitchPass.goWild = 1;
}

function stopGlitch() {
	glitchPass.goWild = 0;
	GlitchShader.uniforms.uRect.value = [0, 0, 0, 0];

	buttons.forEach(button => {
		button.classList.remove('active');
	});
}

function resize() {
	width = container.offsetWidth;
	height = container.offsetHeight;

	const vFOV = THREE.Math.degToRad(camera.fov); // convert vertical fov to radians
	const h = 2 * Math.tan(vFOV / 2) * 10; // visible height
	const w = h * camera.aspect;

	spriteMesh.scale.set(w, h, 1);
	renderer.setSize(width, height);
	composer.setSize(width, height);
}


export default buttonGlitch;
