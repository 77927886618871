import * as THREE from 'three';
import { MathUtils } from 'three';
import preview from '/assets/preview.jpg';
import playImage from '/assets/play.png';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { CustomBloomPass } from './fx/bloom-pass';
import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass';
import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass';
import inView from "in-view";

const container = document.getElementById('video');

function start() {
	const playBtn = document.getElementById('play');

	playBtn.addEventListener('click', e => {
		e.preventDefault();

		glitchActive = false;
		playVideo();
	});

	playBtn.addEventListener('mouseenter', e => {
		glitchActive = true;
	});

	playBtn.addEventListener('mouseleave', e => {

		glitchActive = false;
	});

	const canvas = container.querySelector('canvas');
	const loader = new THREE.TextureLoader();

	var renderer,
		scene,
		camera,
		composer,
		width,
		height,
		spriteMesh,
		play,
		glitchPass,
		glitchActive,
		playing;

	inView("#video")
		.on('enter', () => {
			playing = true;
			resize();
			animate();
		})
		.on('exit', () => {
			playing = false;
		});

	init();
	addSprite();
	addPasses();
	resize();
	animate();
	window.addEventListener('resize', resize);

	function init() {
		scene = new THREE.Scene();
		camera = new THREE.PerspectiveCamera(
			75,
			window.innerWidth / window.innerHeight,
			0.1,
			1000,
		);

		renderer = new THREE.WebGLRenderer({canvas, alpha: true});
		renderer.setClearColor(0x000115, 0); // the default

		camera.position.z = 10;
		camera.fov = 5;
		camera.updateProjectionMatrix();
	}

	function addSprite() {
		const map = loader.load(preview);
		const geometry = new THREE.PlaneBufferGeometry(1, 1);
		const material = new THREE.MeshBasicMaterial({map});
		spriteMesh = new THREE.Mesh(geometry, material);

		const buttonMap = loader.load(playImage, texture => {
			button.scale.y = texture.image.height / texture.image.width;
			button.scale.multiplyScalar(0.2);
		});

		const buttonMaterial = new THREE.SpriteMaterial({map: buttonMap});
		// const buttonMaterial = new THREE.MeshBasicMaterial({color: 0xff0000, wireframe: true});
		const button = new THREE.Sprite(buttonMaterial);
		scene.add(button);

		scene.add(spriteMesh);
	}

	function addPasses() {
		composer = new EffectComposer(renderer);
		const renderPass = new RenderPass(scene, camera);

		const bloomPass = new CustomBloomPass(
			new THREE.Vector2(window.innerWidth, window.innerHeight),
			1.5,
			0.4,
			0.85,
		);
		bloomPass.threshold = 0.5;
		bloomPass.strength = 0.7;
		bloomPass.radius = 0;

		const filmPass = new FilmPass(0.5, 0.2, 1024, 0.2);

		glitchPass = new GlitchPass();
		// glitchActive = true;

		glitchPass.render = function (renderer, writeBuffer, readBuffer /*, deltaTime, maskActive */) {

			this.uniforms['tDiffuse'].value = readBuffer.texture;
			this.uniforms['seed'].value = Math.random();//default seeding
			this.uniforms['byp'].value = 0;

			if (glitchActive && Math.random() > 0.8) {
				this.uniforms['amount'].value = Math.random() / 30;
				this.uniforms['angle'].value = MathUtils.randFloat(-Math.PI, Math.PI);
				this.uniforms['seed_x'].value = MathUtils.randFloat(-1, 1);
				this.uniforms['seed_y'].value = MathUtils.randFloat(-1, 1);
				this.uniforms['distortion_x'].value = MathUtils.randFloat(0, 1);
				this.uniforms['distortion_y'].value = MathUtils.randFloat(0, 1);
				this.curF = 0;

			} else {
				this.uniforms['byp'].value = 1;
			}

			this.curF++;

			if (this.renderToScreen) {

				renderer.setRenderTarget(null);
				this.fsQuad.render(renderer);

			} else {

				renderer.setRenderTarget(writeBuffer);
				if (this.clear) renderer.clear();
				this.fsQuad.render(renderer);

			}
		};

		composer.addPass(renderPass);
		composer.addPass(bloomPass);
		composer.addPass(glitchPass);
		composer.addPass(filmPass);
	}

	function resize() {
		if (!playing) {
			return;
		}
		width = container.offsetWidth;
		height = container.offsetHeight;
		camera.aspect = width / height;
		camera.updateProjectionMatrix();

		const vFOV = THREE.Math.degToRad(camera.fov); // convert vertical fov to radians
		const h = 2 * Math.tan(vFOV / 2) * 10; // visible height
		const w = h * camera.aspect;

		spriteMesh.scale.set(w, h, 1);
		renderer.setSize(width, height);
		composer.setSize(width, height);
	}

	function animate(time = 0) {
		if (!playing) {
			return;
		}
		requestAnimationFrame(animate);
		glitchPass.uniforms['byp'].value = 1;
		composer.render();
	}
}

function playVideo() {
	let iframe = document.getElementById('videoIframe');
	let src = iframe.dataset.src;
	container.classList.add('active');
	iframe.setAttribute('src', src);
}

export default start;
