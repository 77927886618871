import inView from 'in-view';

import consoleBlock from './console';
import landingBlock from './landing';
import dudeAnimation from './dude';
import video from './video';
import counts from './counts';
import projectorGuy from './projector-guy';
import buttonGlitch from './button-glitch';
import footer from './footer-animate';
import './mouse';
import {feedback} from './feedback';
import {menu} from './menu';
import loadVacancies from "./vacancies";

consoleBlock();
loadVacancies();
landingBlock();
dudeAnimation();
projectorGuy();
video();
counts();
feedback();
menu();
scrollIfNeeded();
footer();

buttonGlitch();

const desktopMenu = document.getElementById('menu');

inView('#main')
	.on('enter', () => {
		desktopMenu.classList.remove('active');
	})
	.on('exit', () => {
		desktopMenu.classList.add('active');
	});

inView('.animate-up')
	.on('enter', el => {
		el.classList.add('show-animation');
	});

export function scrollIntoView(href, smooth = true) {
	document.querySelector(href).scrollIntoView({
		behavior: smooth ? 'smooth' : 'auto',
		block: 'center',
	});
}

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
	anchor.addEventListener('click', function (e) {
		e.preventDefault();
		setTimeout(() => scrollIntoView(this.getAttribute('href')), 0);
	});
});

function scrollIfNeeded() {
	if (location.hash) {
		scrollIntoView(location.hash, false);
	}
}
