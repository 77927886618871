import {lerp} from "./utils";
window.addEventListener("deviceorientation", handleOrientation, true);
let width, height;

window.addEventListener("mousemove", mousemove);

window.targetMousePosition = [0, 0];
let currentMousePosition = [0, 0];
animate();

function mousemove(e) {
	currentMousePosition = [
		e.clientX / width - 0.5,
		e.clientY / height - 0.5,
	];
}

function handleOrientation(e) {
	currentMousePosition = [e.gamma / 40, (e.beta - 45) / 40];
}

function animate() {
	requestAnimationFrame(animate);

	targetMousePosition = [
		lerp(targetMousePosition[0], currentMousePosition[0], 0.1),
		lerp(targetMousePosition[1], currentMousePosition[1], 0.1)
	];
}

window.addEventListener("resize", resize);
resize();
function resize() {
	width = window.innerWidth;
	height = window.innerHeight;
}
