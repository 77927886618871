import {getRandomItem, random} from './utils';
import inView from "in-view";

const elem = document.querySelector('.footer__animate');
const RectCount = 60;
let rects = [];
let playing = false;

const Colors = [
	'#CC00FF',
	'#00C2FF',
	'#000000',
	'#2522D5'
];

inView(".footer__animate")
	.on('enter', () => {
		playing = true;
		randomizeAll();
	})
	.on('exit', () => {
		playing = false;
	});

function randomize(child) {
	child.style.cssText = `
		position: absolute;
		top: ${random(0, 40)}px;
		left: ${random(0, Math.max(window.innerWidth, 1920))}px;
		width: ${random(4, 130)}px;
		height: ${random(2, 10)}px;
		background: ${getRandomItem(Colors)};
	`;
}

//
function randomizeAll() {
	const N = random(0, RectCount / 2);
	for (let i = 0; i < N; i++) {
		const child = getRandomItem(rects);
		randomize(child);
	}

	if (playing) {
		setTimeout(randomizeAll, random(10, 250));
	}
}

//
function init() {
	for (let i = 0; i < RectCount; i++) {
		const child = document.createElement('div');
		randomize(child);
		elem.appendChild(child);
		rects.push(child);
	}
	randomizeAll();
}

export default function start() {
	init();
}
