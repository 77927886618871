const innerHeight = require('ios-inner-height');

export function menu() {
	const mobileMenuBtn = document.querySelector('.mobile-menu-btn');
	const mobileMenu = document.querySelector('.mobile-menu');
	const links = Array.from(mobileMenu.querySelectorAll('a'));

	links.forEach(link => link.addEventListener('click', closeMenu));

	mobileMenuBtn.addEventListener('click', toggleMenu);

	let isOpen = false;

	function closeMenu() {
		isOpen = false;
		mobileMenu.removeAttribute('style');
		mobileMenu.classList.remove('active');

		mobileMenuBtn.classList.remove('active');
	}

	function openMenu() {
		isOpen = true;
		mobileMenu.classList.add('active');
		mobileMenu.style.maxHeight = `${innerHeight()}px`;

		mobileMenuBtn.classList.add('active');
	}

	function toggleMenu() {
		isOpen ? closeMenu() : openMenu();
	}
}
