export function lerp(a, b, i) {
	return a + (b - a) * i;
}

export function timeout(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export function random(min, max) {
	return lerp(min, max, Math.random());
}

export function getRandomItem(arr) {
	return arr[Math.floor(Math.random() * arr.length)];
}
