import * as THREE from 'three'
import dude1 from '/assets/dude1.png'
import dude2 from '/assets/dude2.png'
import text1 from '/assets/text/1.png'
import text2 from '/assets/text/2.png'
import text3 from '/assets/text/3.png'
import polygon1 from '/assets/polygons/1.png'
import polygon2 from '/assets/polygons/2.png'
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import {FilmPass} from "three/examples/jsm/postprocessing/FilmPass";
import {CustomGlitchPass} from "./fx/glitch-pass";
import {CustomBloomPass} from "./fx/bloom-pass";
import {lerp, random} from "./utils";
// import * as dat from 'dat.gui';
import inView from "in-view";


window["THREE"] = THREE;
var renderer,
	scene,
	camera,
	tubes,
	bloomPass,
	composer,
	glitchPass,
	sprite2Material,
	triangles,
	trianglesTmp,
	tubesGroup,
	playing;

var width, height;

inView("#dudeCanvasContainer")
	.on('enter', () => {
		playing = true;
		resize();
		animate();
	})
	.on('exit', () => {
		playing = false;
	});

const canvas = document.querySelector("#dudeCanvasContainer canvas");
const canvasContainer = document.querySelector("#dudeCanvasContainer");

function dudeAnimation() {
	scene = new THREE.Scene();
	camera = new THREE.PerspectiveCamera(
		75,
		window.innerWidth / window.innerHeight,
		0.1,
		1000
	);

	renderer = new THREE.WebGLRenderer({canvas, alpha: true});
	renderer.setClearColor(0x000115, 0); // the default

	camera.position.z = 10;
	camera.fov = 5;
	camera.updateProjectionMatrix();

	addSprite();
	addTriangles();
	addSphere();
	addBloom();
	animate();
	flare();

	resize();
	window.addEventListener("resize", resize);
}


function flare() {
	const r = Math.random();
	sprite2Material.opacity = lerp(0.3, 0.4, r);

	setTimeout(flare, random(20, 60));
}

const loader = new THREE.TextureLoader();

function addSprite() {

	const sprite1Map = loader.load(dude1, texture => {
		sprite1.scale.y = texture.image.height / texture.image.width;
	});
	const sprite2Map = loader.load(dude2, texture => {
		sprite2.scale.y = texture.image.height / texture.image.width;
	});
	const sprite1Material = new THREE.SpriteMaterial({map: sprite1Map});
	const sprite1 = new THREE.Sprite(sprite1Material);
	scene.add(sprite1);

	sprite2Material = new THREE.SpriteMaterial({map: sprite2Map});
	const sprite2 = new THREE.Sprite(sprite2Material);
	scene.add(sprite2);
}

function addTriangles() {
	triangles = [polygon1, polygon2]
		.map(url => loader.load(url))
		.map(map => new THREE.SpriteMaterial({map}))
		.map(material => new THREE.Sprite(material));

	triangles[0].position.set(-0.2, -0.1, 0);
	triangles[0].scale.set(0.5, 0.5, 0.5);

	triangles[1].position.set(0.2, 0.1, -0.1);
	triangles[1].scale.set(0.7, 0.7, 0.7);

	triangles
		.forEach(sprite => {
			sprite.originalPosition = sprite.position.clone();
			scene.add(sprite);
		});

	trianglesTmp = triangles.map(() => new THREE.Vector3());
}


function addBloom() {
	bloomPass = new CustomBloomPass(
		new THREE.Vector2(window.innerWidth, window.innerHeight),
		1.5,
		0.4,
		0.85
	);
	bloomPass.threshold = 0.5;
	bloomPass.strength = 1.5;
	bloomPass.radius = 0;

	composer = new EffectComposer(renderer);
	var renderScene = new RenderPass(scene, camera);

	glitchPass = new CustomGlitchPass(8);
	const filmPass = new FilmPass(0.5, 0.2, 1024, 0.2);

	composer.addPass(renderScene);
	composer.addPass(glitchPass);
	composer.addPass(bloomPass);
	composer.addPass(filmPass);
}


function addSphere() {
	const maps = [text1, text2, text3]
		.map(url => {
			const map = new THREE.TextureLoader().load(url);
			map.wrapS = THREE.RepeatWrapping;
			map.wrapT = THREE.RepeatWrapping;
			map.repeat.set(1, 1);

			return map;
		});

	const materials = maps
		.map(map => new THREE.MeshBasicMaterial({map, side: THREE.DoubleSide, alphaTest: 0.2}));


	tubesGroup = new THREE.Group();
	const group = new THREE.Group();

	tubes = materials
		.map((material, index) => {
			const geometry = new THREE.CylinderGeometry(0.2, 0.2, 0.08 / 3, 32, 2, true);
			const tube = new THREE.Mesh(geometry, material);
			tube.position.y = (index - 1) * 0.08 / 3;
			tubesGroup.add(tube);
			return tube;
		});
	group.position.y = 0.25;
	group.rotation.z = 0.15;
	group.rotation.x = -0.1;

	group.add(tubesGroup);
	scene.add(group);
}

function animate() {
	if (!playing) {
		return;
	}
	requestAnimationFrame(animate);
	composer.render();

	if (tubes) {
		tubes[0].rotation.y += 0.01;
		tubes[1].rotation.y -= 0.01;
		tubes[2].rotation.y += 0.005;
	}

	if (triangles) {
		triangles[0].material.rotation += 0.0015;
		triangles[1].material.rotation -= 0.003;
	}

	triangles.forEach((sprite, index) => {
		trianglesTmp[index]
			.set(targetMousePosition[0], -targetMousePosition[1], 0)
			.multiplyScalar((1 - index) * 0.02 + 0.03);

		sprite.position
			.copy(sprite.originalPosition)
			.add(trianglesTmp[index])
	});

	tubesGroup.rotation.set(-0.2 * targetMousePosition[1], 0, -0.2 * targetMousePosition[0]);
}

function resize() {
	if (!playing) {
		return;
	}
	width = canvasContainer.offsetWidth;
	height = canvasContainer.offsetHeight;
	camera.aspect = width / height;
	camera.fov = window.innerWidth < 1024 ? 6 : 5;
	camera.updateProjectionMatrix();
	renderer.setSize(width, height);
	composer.setSize(width, height);
}

export default dudeAnimation;
