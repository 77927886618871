import {GlitchPass} from "three/examples/jsm/postprocessing/GlitchPass";
import {MathUtils, ShaderMaterial, UniformsUtils} from "three";
import {Pass} from "three/examples/jsm/postprocessing/Pass";
import {DigitalGlitch} from "./DigitalGlitch";

export class CustomGlitchPass extends GlitchPass {
	constructor(dt_size) {
		super(dt_size);

		var shader = DigitalGlitch;
		this.uniforms = UniformsUtils.clone(shader.uniforms);
		this.material = new ShaderMaterial({
			uniforms: this.uniforms,
			vertexShader: shader.vertexShader,
			fragmentShader: shader.fragmentShader
		});

		this.fsQuad = new Pass.FullScreenQuad(this.material);
		this.autoGlitch = true;
	}

	render(renderer, writeBuffer, readBuffer /*, deltaTime, maskActive */) {

		this.uniforms["tDiffuse"].value = readBuffer.texture;
		this.uniforms['seed'].value = Math.random();//default seeding
		this.uniforms['byp'].value = 0;

		if (this.goWild && this.small) {
			this.uniforms['amount'].value = Math.random() * 0.01;
			this.uniforms['angle'].value = MathUtils.randFloat(-Math.PI, Math.PI);
			this.uniforms['seed_x'].value = MathUtils.randFloat(-1, 1);
			this.uniforms['seed_y'].value = MathUtils.randFloat(-1, 1);
			this.uniforms['distortion_x'].value = Math.random() > 0.7 ? MathUtils.randFloat(0, 0.5) : 0;
			this.uniforms['distortion_y'].value = Math.random() > 0.7 ? MathUtils.randFloat(0, 0.5) : 0;
			this.curF = 0;
			this.generateTrigger();
		} else if (((this.curF % this.randX == 0 && this.autoGlitch) || this.goWild)) {

			this.uniforms['amount'].value = Math.random() / 30;
			this.uniforms['angle'].value = MathUtils.randFloat(-Math.PI, Math.PI);
			this.uniforms['seed_x'].value = MathUtils.randFloat(-1, 1);
			this.uniforms['seed_y'].value = MathUtils.randFloat(-1, 1);
			this.uniforms['distortion_x'].value = MathUtils.randFloat(0, 1);
			this.uniforms['distortion_y'].value = MathUtils.randFloat(0, 1);
			this.curF = 0;
			this.generateTrigger();

		} else if (this.curF % this.randX < this.randX / 15 && this.autoGlitch) {

			this.uniforms['amount'].value = Math.random() / 90;
			this.uniforms['angle'].value = MathUtils.randFloat(-Math.PI, Math.PI);
			this.uniforms['distortion_x'].value = MathUtils.randFloat(0, 1);
			this.uniforms['distortion_y'].value = MathUtils.randFloat(0, 1);
			this.uniforms['seed_x'].value = MathUtils.randFloat(-0.3, 0.3);
			this.uniforms['seed_y'].value = MathUtils.randFloat(-0.3, 0.3);

		} else if (this.goWild == false) {
			this.uniforms['byp'].value = 1;
		}

		this.curF++;

		if (this.renderToScreen) {

			renderer.setRenderTarget(null);
			this.fsQuad.render(renderer);

		} else {

			renderer.setRenderTarget(writeBuffer);
			if (this.clear) renderer.clear();
			this.fsQuad.render(renderer);

		}
	}

	generateTrigger() {
		this.randX = MathUtils.randInt(200, 400);
	}
}
