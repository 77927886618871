import inView from "in-view";

// Change this to increase or slow typing speed
// The console will type each letter at a random interval between 0ms and `speed` ms.
let speed = 200;
let summaryTime = 3000;
let time = 0;
let prevTime = 0;

let text = "technokratos start ";
let container = document.getElementById("console");

function writeWord(word, callback) {
	container.classList.add("show");

	let textElem = document.getElementById("text");
	let promises = [];

	if (!textElem) return null;

	for (let c in word.split("")) {
		time = Math.floor(Math.random() * speed);
		promises.push(
			new Promise((resolve, reject) => {
				setTimeout(() => {
					textElem.innerHTML += word[c];
					resolve();
				}, prevTime + time);
			})
		);

		prevTime += time;
	}

	Promise.all(promises).then(() => {
		container.classList.remove("show");
		callback();
	});
}

function start() {
	return new Promise((resolve, reject) => {
		writeWord(text, resolve);
	});
}

function runConsole(elem) {
	if (!elem) return null;
	if (elem.classList.contains('animating')) return null;
	let text = elem.dataset.text;
	let prevTime = 0;

	elem.innerHTML = '';
	elem.classList.add('animating');

	let curSpeed = summaryTime / text.length
	let chars = text.split("")
	for (let i = 0; i < chars.length; i++) {
		time = Math.floor(Math.random() * curSpeed);
		setTimeout(() => {
			elem.innerHTML += chars[i];
			if (i === chars.length - 1) {
				elem.classList.remove('animating');
			}
		}, prevTime + time);

		prevTime += time;
	}
}

inView(".console-text").on("enter", el => {
	runConsole(el);
});

export default start;

