import * as THREE from "three";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import {CustomBloomPass} from "./fx/bloom-pass";
import {FilmPass} from "three/examples/jsm/postprocessing/FilmPass";
import polygon6 from '/assets/polygons/6.png'
import polygon7 from '/assets/polygons/7.png'
import polygon8 from '/assets/polygons/8.png'
import feedbackImage from '/assets/feedback.png'
import feedbackMaskImage from '/assets/feedbackMask.png'
import {CustomGlitchPass} from "./fx/glitch-pass";
import {random, timeout} from "./utils";
import inView from "in-view";

export function feedback() {
	var width,
		height,
		composer,
		scene,
		renderer,
		camera,
		triangles,
		trianglesTmp,
		maskSprite,
		glitchPass,
	playing;

	const canvas = document.querySelector("#feedbackCanvas canvas");
	const canvasContainer = document.querySelector("#feedbackCanvas");

	inView("#feedbackCanvas")
		.on('enter', () => {
			playing = true;
			resize();
			animate();
		})
		.on('exit', () => {
			playing = false;
		});
	const loader = new THREE.TextureLoader();

	init();

	addSprite();
	addTriangles();

	addPasses();
	resize();
	animate();
	flare();
	window.addEventListener("resize", resize);

	async function flare() {
		/*const r = Math.random();
		maskSprite.material.opacity = r > 0.8 ? 1 : 0;*/

		// setTimeout(flare, random(20, 60));
		glitchPass.goWild = true;
		await timeout(random(25, 150));
		maskSprite.material.opacity = 1;
		await timeout(random(25, 150));

		glitchPass.goWild = false;
		await timeout(random(50, 300));
		glitchPass.goWild = true;
		await timeout(random(25, 150));
		maskSprite.material.opacity = 0;
		await timeout(random(25, 150));
		glitchPass.goWild = false;

		setTimeout(flare, random(600, 3000));
	}

	function addTriangles() {
		triangles = [
			polygon6,
			polygon7,
			polygon8
		]
			.map((url, i) => loader.load(url))
			.map(map => new THREE.SpriteMaterial({map}))
			.map(material => new THREE.Sprite(material));

		triangles[0].position.set(-0.2, -0.1, 0);
		triangles[0].scale.set(0.6, 0.6, 0.6);
		triangles[1].position.set(0.3, -0.1, -0.1);
		triangles[1].scale.set(0.5, 0.5, 0.5);
		triangles[2].position.set(0.2, 0, -0.2);
		triangles[2].scale.set(0.5, 0.5, 0.5);

		triangles
			.forEach(sprite => {
				sprite.originalPosition = sprite.position.clone();
				scene.add(sprite);
			});

		trianglesTmp = triangles.map(() => new THREE.Vector3());
	}

	function getCamera() {
		const cam = new THREE.PerspectiveCamera(
			75,
			window.innerWidth / window.innerHeight,
			0.1,
			1000
		);

		cam.position.z = 10;
		cam.position.x = 0;
		cam.fov = 5;
		cam.updateProjectionMatrix();
		return cam;
	}

	function init() {
		scene = new THREE.Scene();
		camera = getCamera();

		renderer = new THREE.WebGLRenderer({canvas, alpha: true});
		renderer.setClearColor(0x000115, 0); // the default
	}

	function addPasses() {
		composer = new EffectComposer(renderer);
		const renderPass = new RenderPass(scene, camera);

		const bloomPass = new CustomBloomPass(
			new THREE.Vector2(window.innerWidth, window.innerHeight),
			1.5,
			0.4,
			0.85
		);
		bloomPass.threshold = 0.5;
		bloomPass.strength = 0.7;
		bloomPass.radius = 0;

		glitchPass = new CustomGlitchPass();
		glitchPass.goWild = false;
		glitchPass.autoGlitch = false;

		const filmPass = new FilmPass(0.5, 0.2, 1024, 0.2);

		composer.addPass(renderPass);
		composer.addPass(bloomPass);
		composer.addPass(filmPass);
		composer.addPass(glitchPass);
	}

	function addSprite() {
		const spriteMap = loader.load(feedbackImage, texture => {
			sprite.scale.y = texture.image.height / texture.image.width;
			sprite.scale.multiplyScalar(1.4);
		});
		const spriteMaterial = new THREE.SpriteMaterial({map: spriteMap});
		const sprite = new THREE.Sprite(spriteMaterial);


		const maskSpriteMap = loader.load(feedbackMaskImage, texture => {
			maskSprite.scale.y = texture.image.height / texture.image.width;
			maskSprite.scale.multiplyScalar(1.4);
		});
		const maskSpriteMaterial = new THREE.SpriteMaterial({map: maskSpriteMap});
		maskSprite = new THREE.Sprite(maskSpriteMaterial);

		scene.add(sprite);
		scene.add(maskSprite);
	}

	function animate(time = 0) {
		if(!playing) {
			return;
		}
		requestAnimationFrame(animate);

		if (triangles) {
			triangles[0].material.rotation += 0.0015;
			triangles[1].material.rotation -= 0.0007;
			triangles[2].material.rotation += 0.0007;
		}

		triangles.forEach((sprite, index) => {
			trianglesTmp[index]
				.set(targetMousePosition[0], -targetMousePosition[1], 0)
				.multiplyScalar((1 - index) * 0.02 + 0.03);

			sprite.position
				.copy(sprite.originalPosition)
				.add(trianglesTmp[index])
		});

		composer.render();
	}


	function resize() {
		if(!playing) {
			return;
		}
		width = canvasContainer.offsetWidth;
		height = canvasContainer.offsetHeight;
		camera.aspect = width / height;
		const halfHeight = 1 / 2;
		console.log();

		camera.fov = window.innerWidth < 1024 ? THREE.Math.radToDeg(Math.atan(halfHeight / 10)) * 2 : 7;
		camera.updateProjectionMatrix();
		renderer.setSize(width, height);
		composer.setSize(width, height);
	}
}
